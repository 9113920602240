import React from 'react';

const Disclaimer = ({ isOpen, onClose }) => {

  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
      <div className='bg-white p-8 rounded-lg shadow-lg flex flex-col items-center max-w-md mx-auto'>
        <h1 className="text-2xl font-bold mb-4">Note</h1>
        <p className="mb-4 text-lg text-center">
          Schengen Visa is applicable for <a href='https://www.udi.no/en/word-definitions/schengen--the-schengen-area/' className='underline' target='blank'>these</a> 29 countries. For UK and Ireland, one has to book separately.
        </p>
        <button 
          onClick={onClose} 
          className="mt-4 px-4 py-2 bg-[#191983] text-white rounded-lg focus:outline-none"
        >
          I understand
        </button>
      </div>
    </div>
  );
};

export default Disclaimer;