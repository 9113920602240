import {BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { GoogleOAuthProvider } from '@react-oauth/google';
function App() {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT;
  

  if (!clientId) {
    console.error('GOOGLE_CLIENT_ID is not set in the environment variables');
    // You might want to render an error message or fallback UI here
    return <div>Error: Google Client ID is not configured</div>;
  }

  return (
    <GoogleOAuthProvider clientId={clientId}>
    <Router >
      <AppRoutes/>
    </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
