import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedOption } from '../redux/slices/pricingSlice';
import background from "../assets/loginbackground.webp";
import FinalPage from './FinalPage';


const ChoosePrice = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const navigate = useNavigate();
  const selectedOption = useSelector((state) => state.pricing.selectedOption);

  const PricingCard = ({ title, subtitle, features }) => (
    <div className="bg-white rounded-2xl p-2 m-2 lg:h-[290px] w-full md:w-80 flex flex-col">
      <h2 className="text-lg sm:text-xl text-center font-bold mb-1" style={{ color: '#000080' }}>{title}</h2>
      <p className="text-black text-center font-semibold text-sm sm:text-base mb-4">{subtitle}</p>
      <div className="flex-grow">
        {features.map((feature, index) => (
          <div key={index} className="flex items-center mb-2">
            <img className="mr-2" src={require('../assets/checked.png')} alt="" style={{ width: 18, height: 18 }} />
            <p className="text-gray-900 text-sm sm:text-base ml-1">{feature}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const getReviewedFeatures = [
    'Reviewing each document and information being shared',
    'ONE meeting only',
    'Sharpen and improve the application',
    'Provide the current trends with the targeted countries'
  ];

  const azPrepFeatures = [
    'PREPARING each document with information being shared',
    'Draft complete itinerary for your trip',
    'Dedicated 3hrs of meeting(broken into 30m-1hr slots)',
    'Unlimited emails and telegram support'
  ];

  const handleOptionChange = (option) => {
    dispatch(setSelectedOption(option));
  };

  const getPrice = () => {
    if (selectedOption === 'Get Reviewed') {
      return 1000;
    } else if (selectedOption === 'A-Z prep') {
      return 2000;
    }
    return 0;
  };

  const handleSubmit = () => {
    if (selectedOption) {
      setModalOpen(true);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-4" style={{ background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))" }}>
      <div className="absolute inset-0 bg-cover bg-center opacity-50" style={{ backgroundImage: `url(${background})`, opacity: 0.08 }}></div>
      <div className="bg-white rounded-lg shadow-lg p-6 md:p-8 w-full relative max-w-3xl">
        <div className="absolute">
          <button className='mt-4 ml-4' onClick={() => { navigate('/appointment') }}><img src={require('../assets/back-button.png')} alt='previous' /></button>
        </div>
        <div className="text-center mb-8">
          <h2 className="text-xl sm:text-2xl font-bold text-[#191983] m-2">
            4/4 Pricing
          </h2>
        </div>

        <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-4 mb-6">
          <label className="cursor-pointer">
            <input
              type="radio"
              name="pricingOption"
              value="Get Reviewed"
              className="hidden"
              checked={selectedOption === 'Get Reviewed'}
              onChange={() => handleOptionChange('Get Reviewed')}
            />
            <div className={`border-2 rounded-2xl overflow-hidden ${selectedOption === 'Get Reviewed' ? 'border-blue-800 shadow-xl' : 'border-gray-300'}`}>
              <PricingCard
                title="Get Reviewed"
                subtitle="(recommended for experienced folks)"
                features={getReviewedFeatures}
              />
            </div>
          </label>
          <label className="cursor-pointer mt-4 sm:mt-0">
            <input
              type="radio"
              name="pricingOption"
              value="A-Z prep"
              className="hidden"
              checked={selectedOption === 'A-Z prep'}
              onChange={() => handleOptionChange('A-Z prep')}
            />
            <div className={`border-2 rounded-2xl overflow-hidden ${selectedOption === 'A-Z prep' ? 'border-blue-800 shadow-xl' : 'border-gray-300'}`}>
              <PricingCard
                title="A-Z prep"
                subtitle="(new-bie or first timers)"
                features={azPrepFeatures}
              />
            </div>
          </label>
        </div>

        {selectedOption && (
          <div className="flex flex-col items-center mt-6">
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={isConfirmed}
                onChange={() => setIsConfirmed(!isConfirmed)}
              />
              {/* <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div> */}
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#191983]"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-black-300">
                {isConfirmed ? "I confirm" : "I don't confirm"}
              </span>
            </label>
            <p className="text-sm text-gray-600 mt-2 max-w-md text-center">
              {isConfirmed
                ? `I have provided complete and accurate information  
        understanding that hiding any relevant details could adversely affect the outcome of our efforts to get you visa smoothly.`
                : `I have provided complete and accurate information  
        understanding that hiding any relevant details could adversely affect the outcome of our efforts to get you visa smoothly.`}
            </p>
            {!isConfirmed && (
              <p className="text-sm text-red-500 font-semibold mt-2">
                You must confirm to proceed with the payment.
              </p>
            )}
            <button
              type="button"
              className={`bg-[#191983] text-white font-bold py-2 px-4 rounded-lg mt-4 ${
                !isConfirmed ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={isConfirmed ? handleSubmit : () => {}}
              disabled={!isConfirmed}
            >
              Confirm & Pay ₹{getPrice()}
            </button>
          </div>
        )}
      </div>
      <FinalPage isOpen={isModalOpen} onClose={() => setModalOpen(false)} />

    </div>
  );
};

export default ChoosePrice;
