// src/PrivacyPolicy.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="bg-white py-12 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
          <div className="prose prose-lg">
            <p className="text-gray-700 mb-4">Last updated: 27th June 2024</p>
            <p className="mb-4">
              This Privacy Policy describes how Viszapp (“we”, “us”, or “our”) collects, uses, and shares personal information when you use our website https://viszapp.com.
              Please read this policy carefully to understand our practices regarding your personal data and how we will treat it.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Information We Collect</h2>
            <p className="mb-4">
              We collect personal information that you provide to us when you interact with our website. This may include your name, email address, phone number, and any other information you choose to provide.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">How We Use Your Information</h2>
            <p className="mb-4">
              We use the information we collect to provide and improve our services, personalize your experience, communicate with you, and ensure the security of our website.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Sharing Your Information</h2>
            <p className="mb-4">
              We do not sell, trade, or otherwise transfer your personal information to outside parties unless we provide you with advance notice, except as described below. The term "outside parties" does not include Viszapp. It also does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Security of Your Information</h2>
            <p className="mb-4">
              We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Changes to This Policy</h2>
            <p className="mb-4">
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page. You are advised to review this Privacy Policy periodically for any changes.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Contact Us</h2>
            <p className="mb-4">
              If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@viszapp.com" className="text-blue-600">support@viszapp.com</a>.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
