import React, { useEffect, useState } from 'react';

const TestimonialCard = ({ name, imageUrl, testimonial }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md h-full flex flex-col">
      <div className="flex items-center mb-4">
        <img src={imageUrl} alt={name} className="w-12 h-12 rounded-full mr-4 object-cover" />
        <h3 className="font-semibold text-lg">{name}</h3>
      </div>
      <p className="text-gray-600 flex-grow text-sm sm:text-base">{testimonial}</p>
    </div>
  );
};

const testimonials = [
  {
    id: '1',
    name: 'Gagandeep Singh',
    imageUrl: 'https://pbs.twimg.com/profile_images/1160836453909139456/m3iqKUeU_400x400.jpg',
    testimonial: `A few days ago I got my schengen visa after 2 refusals and a person who truly deserves a credit in 
    this journey is @Kenu73 He has always extended a helping hand whenever and wherever In needed him 
    He is a great specialist at preparing cover letters for schengen visa`,
  },
  {
    id: '2',
    name: 'Sharad Dube',
    imageUrl: 'https://pbs.twimg.com/profile_images/1432569025108721668/S0C-PO6__400x400.jpg',
    testimonial: `
    Hello,
    
    I had been following Priyesh on Twitter for a while and found his insights valuable. When my Schengen visa from Austria was denied,
     I sought his help, and his clear guidance on preparing a strong cover letter helped me get my visa approved on the second attempt. 
    Priyesh's assistance was invaluable, and he deserves all the appreciation for his support. Thank you, Priyesh!`,
  },
  {
    id: '3',
    name: 'Tanisha Singh',
    imageUrl: 'https://pbs.twimg.com/profile_images/1725840910142095360/2pPBxRXS_400x400.jpg',
    testimonial: `After a visa rejection from Austria and getting scammed by an agent, 
    I was lost with only a month until my travel date. Priyesh, found via CardExpert, guided me 
    through every detail, and I got my visa in a week. His expertise in visas and trip planning is exceptional; highly recommend!`,
  },
  {
    id: '4',
    name: 'Soumyadeep Dhar',
    imageUrl: 'https://pbs.twimg.com/profile_images/1039622208967135232/a_1v3rHL_400x400.jpg',
    testimonial: `Extremely grateful for the invaluable assistance with visa and international travel matters. Your deep knowledge and insightful guidance have been incredibly helpful. 
    Your willingness to share your expertise and support to those seeking help is truly commendable! 
    100% recommend for first time travelers.`,
  },
  {
    id: '5',
    name: 'Harshit',
    imageUrl: 'https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png',
    testimonial: `Thank you for your constant support and guidance throughout the UK & Schengen visa process for my family trip. Your insights on itinerary 
    and attractions, along with your availability to answer even the smallest queries, made everything smooth and timely. 
    Appreciate your patience, approachability, and expertise—thanks again!`,
  },
  {
    id: '6',
    name: 'TheBest',
    imageUrl: 'https://pbs.twimg.com/profile_images/1753852519707480065/36SW51Q7_400x400.jpg',
    testimonial: `I don't know how to thank Priyesh enough. After wasting almost 45k with agents from a big-shot agency 
    and facing three refusals, 
    I finally got my first visa purely because of his strategy. His guidance was invaluable and highly effective!`,
  },
];

const TestimonialsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 2);
    }, 7500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="p-4 sm:p-8 m-4 sm:m-8 bg-gray-100">
      <h2 className="text-3xl sm:text-5xl font-bold text-center mt-5 mb-10">Testimonials</h2>
      <div className="overflow-hidden">
        <div 
          className="flex transition-transform duration-500 ease-in-out" 
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {[0, 1].map((pageIndex) => (
            <div key={pageIndex} className="w-full flex-shrink-0">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {testimonials.slice(pageIndex * 3, pageIndex * 3 + 3).map((item) => (
                  <div key={item.id} className="p-4">
                    <TestimonialCard {...item} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        {[0, 1].map((index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full mx-2 ${index === currentIndex ? 'bg-blue-500' : 'bg-gray-300'}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default TestimonialsSection;