import React, { useEffect } from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import confetti from 'canvas-confetti';

const offers = [
  {
    id: 1,
    title: 'Exclusive Launch Offer',
    description: "Celebrate our grand opening with an unbeatable deal! Our premium visa assistance service is now available at a flat rate, regardless of the number of applicants in your immediate family. Don't miss this limited-time opportunity to streamline your visa process!",
    details: [
      "Fixed price for families",
      "Expert guidance throughout",
      "Service Satisfaction End-to-End",
      "24/7 support"
    ]
  }
];

const OffersPage = () => {
  useEffect(() => {
    const duration = 15 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(function() {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
      confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
    }, 250);
  }, []);

  return (
    <>
      <Header />
      <div className="min-h-screen bg-gradient-to-b from-blue-100 to-blue-200">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <h1 className="text-5xl font-extrabold text-center text-blue-800 mb-12 animate-pulse">
            🎉 Exciting Launch Offer! 🎉
          </h1>
          <div className="grid grid-cols-1 gap-8">
            {offers.map(offer => (
              <div key={offer.id} className="bg-white shadow-lg rounded-2xl overflow-hidden transform hover:scale-105 transition duration-300">
                <div className="px-8 py-10">
                  <h2 className="text-3xl font-bold mb-6 text-blue-600">{offer.title}</h2>
                  <p className="text-gray-700 mb-8 text-lg leading-relaxed">{offer.description}</p>
                  <div className="bg-blue-50 p-6 rounded-xl">
                    <h3 className="text-xl font-semibold mb-4 text-blue-800">What's Included:</h3>
                    <ul className="list-disc pl-5 space-y-2">
                      {offer.details.map((detail, index) => (
                        <li key={index} className="text-gray-700">{detail}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-8 text-center">
                  <Link to="/visaSelection">
      <button className="bg-blue-600 text-white font-bold py-3 px-8 rounded-full hover:bg-blue-700 transition duration-300 transform hover:scale-105">
        Claim Offer Now
      </button>
    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OffersPage;
