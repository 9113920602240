import React, { useState } from 'react';
import background from "../assets/loginbackground.webp"
import showPasswordIcon from '../assets/show-password.png';
import hidePasswordIcon from '../assets/hide.png';
import { jwtDecode } from 'jwt-decode';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Turnstile, { useTurnstile } from "react-turnstile";
import config from '../config';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const SignUp= () => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const turnstile = useTurnstile();
  const [turnstileToken, setTurnstileToken] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const validateForm = () => {
    const errors = {};
    if (!name) errors.name = "Name is required";
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }
    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }
    return errors;
  };
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  
  const handleGoogleSignUp = async (credentialResponse) => {
    setIsLoading(true);
    const decoded = jwtDecode(credentialResponse.credential);
    const { name, email, sub } = decoded;
    try {
      const signUpResponse = await axios.post(`${config.apiBaseUrl}/api/users/google-signin`, { 
        email, gid: sub, name, turnstileToken
      });
        if (signUpResponse.status === 200) {
          localStorage.setItem('accessToken', signUpResponse.data.token);
          Toast.fire({
            icon: "success",
            title: "Logged in successfully"
          });
          navigate('/dashboard');
        } else {
          turnstile.reset();
          Toast.fire({
            icon: "error",
            title: "Login failed after successful sign up"
          });
        }
     
    } catch (error) {
      turnstile.reset();
      console.error('Error during Google Sign Up:', error);
      Toast.fire({
        icon: "error",
        title: error.response?.data?.message || "An unexpected error occurred during Google Sign Up"
      });
    }
    finally {
      setIsLoading(false);
    }
  };
  
  const handleManualSignUp = async (e) => {
    e.preventDefault();
    setFormErrors({});
    setIsLoading(true);
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsLoading(false);
      return;
    }
    try {
      const signUpResponse = await axios.post(`${config.apiBaseUrl}/api/users/signup`, { 
        name, 
        email, 
        password,
        mode: "signup",
        turnstileToken
      });
      
      if (signUpResponse.data.success) {
        Toast.fire({
          icon: "success",
          title: "Sign up successful"
        });
  
        const loginResponse = await axios.post(`${config.apiBaseUrl}/api/users/login`, { 
          email, 
          password, 
          mode: "signup" 
        });
        
        if (loginResponse.data.success) {
          localStorage.setItem('accessToken', loginResponse.data.token);
          Toast.fire({
            icon: "success",
            title: "Logged in successfully"
          });
          navigate('/dashboard');
        } else {
          Toast.fire({
            icon: "error",
            title: "Login failed after successful sign up"
          });
          turnstile.reset();
        }
      }
      else if(signUpResponse.status === 201 || signUpResponse.status === 200)
      {
        Toast.fire({
          icon: "success",
          title: signUpResponse.data.message
        });
        navigate('/login');
      }
       else {
        Toast.fire({
          icon: "error",
          title: signUpResponse.data.message || "Sign up failed"
        });
        turnstile.reset();
      }
    } catch (error) {
      turnstile.reset();
      console.error('Error during manual Sign Up:', error);
      Toast.fire({
        icon: "error",
        title: error.response?.data?.message || "An unexpected error occurred during sign up"
      });
    }
    finally {
      setIsLoading(false);
    }  
  };


  return (
    <div className="flex flex-col items-center justify-center min-h-screen " style={{background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))", 
    }}>
         <div
        className="absolute inset-0 bg-cover bg-center opacity-50"
        style={{ backgroundImage: `url(${background})`, opacity:0.08 }}      ></div>
      <h2 className="text-2xl font-bold text-center text-white mb-5">Viszapp</h2>
      <h3 className="text-3xl font-semibold text-center text-white mb-9">Sign up</h3>
      <div className="relative z-10 bg-white p-5 rounded-xl shadow-lg max-w-md w-full  ">
        <div className='m-4'>
        <div className="flex justify-center mb-3">
        <GoogleLogin
    onSuccess={handleGoogleSignUp}
    onError={() => {
      console.log('Google Login Failed');
      Toast.fire({
        icon: "error",
        title: "Google Sign Up failed"
      });
    }}
    uxMode="redirect"
    redirectUri="https://viszapp.com/signup"
  />
  {isLoading && <div className="loader absolute right-0 top-1/2 transform -translate-y-1/2 mr-2"></div>}

        </div>
        <p className="text-center text-gray-600 mb-1">Or</p>
        <form onSubmit={handleManualSignUp}>
          <div className="mb-4">
            <label className="block text-black mb-2" htmlFor="name">Name</label>
            <input className="w-full p-2 border border-gray-300 rounded-md" type="text" id="name"  value={name}
                onChange={(e) => setName(e.target.value)} placeholder="Name" />
                {formErrors.name && <p>{formErrors.name}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-black mb-2" htmlFor="email">Email</label>
            <input className="w-full p-2 border border-gray-300 rounded-md" type="email" id="email" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email" />
            {formErrors.email && <p>{formErrors.email}</p>}
          </div>
          <div className="mb-4">
      <label className="block text-black mb-2" htmlFor="password">Password</label>
      <div className="relative">
        <input
          type={showPassword ? 'text' : 'password'}
          id="password"
          className="w-full p-3 border rounded-lg focus:outline-none mb-2 focus:border-blue-500"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        /> 
        {formErrors.password && <p>{formErrors.password}</p>}
          <button
        type="button"
        className="absolute right-3 top-3"
        onClick={toggleShowPassword}
      >
        {showPassword ? (
          <img src={showPasswordIcon} alt="Show Password" className="h-7 w-7" />
        ) : (
          <img src={hidePasswordIcon} alt="Hide Password" className="h-6 w-6" />
        )}
      </button>
      </div>
    </div>
<Turnstile
      sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
      theme='light'
      size='flexible'
      onVerify={(token) => {
        setTurnstileToken(token); 
      }}
    />
    <button 
  className="w-full bg-blue-700 text-white p-2 rounded-md font-semibold flex items-center mt-3 justify-center" 
  type="submit"
  disabled={isLoading}
>
  {isLoading ? (
    <div className="loader mr-2"></div>
  ) : null}
  Create account
</button>
<div className="text-center mt-4">
            <p className="text-black text-sm">
              By Signing up, you agree to the <a href="/terms" className="text-blue-900">T&C</a>, <a href="/cancellation" className="text-blue-900">Cancellation</a>, and <a href="/privacy" className="text-blue-900">Privacy Policy</a>.
            </p>
          </div>
        </form>
        <div className="text-center mt-6">
          <p className="text-black">Already Have An Account? <a href="/login" className="text-blue-900">Login</a></p>
        </div>
        </div>
        
      </div>
    </div>
  );
};

export default SignUp;
