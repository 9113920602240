import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import PromoCodeModal from '../shared/PromoCodeModal';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import config from '../config';

const FinalPage = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const selectedVisa = useSelector((state) => state.visa.selectedVisa);
  const bankStatement = useSelector((state) => state.attachdocumentSlice.bankStatement);
  const nationality = useSelector((state) => state.attachdocumentSlice.nationality);
  const occupation = useSelector((state) => state.attachdocumentSlice.occupation);
  const additionalInfo = useSelector((state) => state.attachdocumentSlice.additionalInfo);
  const selectedDate = useSelector((state) => state.appointment.selectedDate);
  const selectedTime = useSelector((state) => state.appointment.selectedTime);
  const selectedOption = useSelector((state) => state.pricing.selectedOption);
  const [phoneError, setPhoneError] = useState('');
  const [promoModalOpen, setPromoModalOpen] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [value, setValue] = useState('');
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const [personName, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
let bookingID;
  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email address').required('Email is required'),
  });

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handlePhoneChange = (newValue) => {
    setValue(newValue);
    if (phoneError && isValidPhoneNumber(newValue)) {
      setPhoneError('');
    }
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  }
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 7000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  async function displayRazorpay(formData) {

    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) {
      alert('Razorpay SDK failed to load!');
      setLoading(false);
      return;
    }
    const check = await saveBookingData(formData);
    if(!check)
    {
      setLoading(false);
      Toast.fire({
        icon: "error",
        title: "Invalid Date/Time format, please choose again!"
      });
      navigate('/visaSelection');
      return;
    }
    else{
    try {
      const visaSelection = selectedOption;
      const email = formData.email; // Get the email value from the form data
      const name= personName;
      const phone = value;
      const currency = 'INR';
      const response = await fetch(`${config.apiBaseUrl}/user/payment/razorpay`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({ visaSelection, email, name, phone, currency }),
      });
      const data = await response.json();

      if (!data || !data.orderId) {
        console.error('Invalid response from server:', data);
        // alert('Failed to create order. Please try again.', data);
        setLoading(false); 
        return;
      }

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, 
        amount: data.amount,
        currency: data.currency,
        name: 'Viszapp',
        description: personName + selectedOption,
        image: 'https://viszapp.com/logo.png',
        order_id: data.orderId,
        // callback_url: `${config.apiBaseUrl}/user/payment/verify`,
        prefill: {
          name: personName,
          email: email,
          contact: phone,
        },
        theme: {
          color: '#000080',
        },
        
        handler: async function (response) {

          // Call the verification API
          try {
            const verifyResponse = await fetch(`${config.apiBaseUrl}/user/payment/verify`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              },
              body: JSON.stringify({ response }),
            });

            const verifyData = await verifyResponse.json();
            if (verifyData.status === 'success') {
              Toast.fire({
                icon: "success",
                title: "Please wait, we are confirming the order..."
              });
              
              await updateBooking(bookingID, "processing", response.razorpay_payment_id, "Success")
  .then(success => {
    if (success) {
      navigate('/dashboard');
      // console.log('Booking updated successfully');
    } else {
      console.log('Failed to update booking');
    }
  })
  .catch(error => {
    console.error('Error updating booking:', error.message);
  });
            } else {
              Toast.fire({
                icon: "fail",
                title: "Payment Failed, please retry!"
              });
              // alert('Payment verification failed. Please try again.');
            }
          } catch (error) {
            console.error('Error verifying payment:', error);
            // alert('Oops! Something went wrong.\nError in verifying payment');
          }
        },
        modal: {
          ondismiss: function () {
            console.log('Checkout form closed');
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      setLoading(false);
      paymentObject.open();
    } catch (error) {
      console.error('Error creating Razorpay order:', error);
      // alert('Oops! Something went wrong.\nError in opening checkout', error);
      setLoading(false);
    }
  }
  }
  const updateBooking = async (bookingId, bookstatus, razid, razStatus) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/bookings/updatebookings/${bookingId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({ bookstatus, razid, razStatus })
      });
  
      if (!response.ok) {
        throw new Error('Failed to update booking');
      }
  
      // const data = await response.json();
      return true; // Return true if update was successful
    } catch (error) {
      // console.error('Error updating booking:', error.message);
      return false; // Return false if there was an error
    }
  };
  
  

 const saveBookingData = async (data) => {
    const bookingData = {
      amount: getPrice(),
      bookStatus: "Payment Due",
      details: {
        name: personName,
        email: data.email,
        phone: value,
        visatype: selectedVisa,
        drive: bankStatement,
        nation: nationality,
        occ: occupation,
        anything: additionalInfo,
        plan: selectedOption,
        promo: discountedPrice ? 'Applied' : 'None',
      },
      appointment: {
        datetime:  formatDateString(new Date(selectedDate).toLocaleDateString('en-GB')) + " "+ new Date(selectedTime).toLocaleTimeString('en-GB'),
        name: personName,
        email: data.email,
        link: 'None',
        status: 'Scheduled',
      },
      payment: {
        amount: getPrice(),
        razid: "pending",
        paymode: 'Razorpay',
        status: 'Pending',
      },
      slot: {
        date: formatDateString(new Date(selectedDate).toLocaleDateString('en-GB')),
        time: formatDateString(new Date(selectedDate).toLocaleDateString('en-GB')) + " "+ new Date(selectedTime).toLocaleTimeString('en-GB') // Format time as HH:MM:SS
      },
    };

    try {
      const response = await fetch(`${config.apiBaseUrl}/api/bookings/book`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify(bookingData),
      });
      const responseData = await response.json();
      if (response.status === 201 ) {
        bookingID = responseData.bookingId;
        return true;
      } else {
        console.error('Failed to save booking');
        return false;
      }
    } catch (error) {
      console.error('Error saving booking');
      return false;
    }
  };
  

  function formatDateString(dateString) {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }

  const getPrice = () => {
    let price = 0;
    if (selectedOption === 'Get Reviewed') {
      price = 1000;
    } else if (selectedOption === 'A-Z prep') {
      price = 2000;
    }
    return discountedPrice !== null ? discountedPrice : price;
  };

  const handleEdit = (step) => {
    onClose();
    switch (step) {
      case 1:
        navigate('/visaSelection');
        break;
      case 2:
        navigate('/attachDocuments');
        break;
      case 3:
        navigate('/appointment');
        break;
      case 4:
        navigate('/choosePrice');
        break;
      default:
        break;
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    if (
      !selectedVisa ||
      !nationality ||
      !occupation ||
      !selectedDate ||
      !selectedTime ||
      !selectedOption ||
      !nameRef.current.value ||
      !emailRef.current.value ||
      !value ||
      !isValidPhoneNumber(value)
    ) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "error",
        title: "Some data is missing!"
      });
      setLoading(false);
      return;
    }

    if (!isValidPhoneNumber(value)) {
      setPhoneError('Invalid phone number');
      setLoading(false);
      return;
    }
    setPhoneError('');
    try {
      await displayRazorpay(data);
    } catch (error) {
      console.error('Error during payment:', error);
      // Handle error
    }
    onClose();
  };

  const applyPromoCode = (newPrice) => {
    setDiscountedPrice(newPrice);
  };

  const openPromoModal = (e) => {
    e.preventDefault();
    setPromoModalOpen(true);
  };

  const Spinner = ({ message }) => (
    <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-5 rounded-lg shadow-lg text-center">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900 mx-auto mb-4"></div>
        <p className="text-lg font-semibold">{message}</p>
      </div>
    </div>
  );

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-auto">
      {loading ? (
        <Spinner message="Please don't press back or interrupt now" />
      ) : (
        <div className="bg-white rounded-lg m-3 px-3 shadow-lg w-full max-w-lg overflow-auto max-h-screen">
          <form onSubmit={handleSubmit(onSubmit)} className="p-4 relative">
            <button onClick={onClose} className="text-red-500 hover:text-black text-2xl absolute right-2 top-4">
              &times;
            </button>
            <h2 className="text-2xl font-bold text-center text-[#000080] mb-7">Review Your Details</h2>

            <div className="mb-4 flex flex-row my-2 justify-between">
              <h3 className="text-xl font-bold">Selected Visa</h3>
              <p>{selectedVisa}</p>
              <button onClick={() => handleEdit(1)} className="text-blue-600">Edit</button>
            </div>

            <div className="mb-4">
              <div className="flex justify-between items-center mb-3">
                <h3 className="text-xl font-bold">Personal Details</h3>
                <button onClick={() => handleEdit(2)} className="text-blue-600">Edit</button>
              </div>
              <p className="mb-1 text-wrap"><span className="font-semibold text-gray-700">Bank Statement:</span> <span className="mx-2  ">{bankStatement}</span></p>
              <p className="mb-1"><span className="font-semibold text-gray-700">Nationality:</span> <span className="mx-3">{nationality}</span></p>
              <p className="mb-1"><span className="font-semibold text-gray-700">Occupation:</span> <span className="mx-3">{occupation}</span></p>
              <p className="mb-1"><span className="font-semibold text-gray-700">Additional Info:</span> <span className="mx-3">{additionalInfo}</span></p>
            </div>

            <div className="mb-4">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-xl font-bold mb-2">Appointment</h3>
                <button onClick={() => handleEdit(3)} className="text-blue-600">Edit</button>
              </div>
              <div className="bg-white max-w-md mx-auto">
                <div className="mb-1">
                  <p className="flex items-center">
                    <span className="font-semibold text-gray-700 w-24">Date:</span>
                    <span>{selectedDate ? new Date(selectedDate).toLocaleDateString('en-GB') : 'Not set'}</span>
                    <span className="mx-5">{selectedTime ? new Date(selectedTime).toLocaleTimeString('en-GB') : 'Not set'}</span>
                  </p>
                </div>

                <div className="mb-1">
                  <p className="flex items-center">
                    <span className="font-semibold text-gray-700 w-24">Name:</span>
                    <input
                      type="text"
                      ref={nameRef}
                      value={personName}
                      onChange={(e) => setName(e.target.value)}
                      className="flex-grow m-1 border p-1 rounded-md border-gray-400"
                      required
                    />
                  </p>
                </div>

                <div className="mb-1">
                  <p className="flex items-center">
                    <span className="font-semibold text-gray-700 w-24">Email:</span>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          {...field}
                          ref={emailRef}
                          className={`flex-grow m-1 border rounded-md p-1 border-gray-400 ${errors.email ? 'border-red-500' : ''}`}
                        />
                      )}
                    />
                  </p>
                  {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
                </div>

                <div className="mb-1">
                  <p className="flex items-center">
                    <span className="font-semibold text-gray-700 w-24">Mobile no:</span>
                    <PhoneInput
                      placeholder="Enter phone number"
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="IN"
                      ref={phoneRef}
                      value={value}
                      onChange={handlePhoneChange}
                      className="flex-grow m-1 p-1 border rounded-md border-gray-400"
                      id="number"
                    />
                  </p>
                  {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}
                </div>

              </div>
            </div>

            <div className="mb-4 flex flex-row my-2 justify-between">
              <h3 className="text-xl font-bold">Pricing Option</h3>
              <p>{selectedOption}</p>
              <button onClick={() => handleEdit(4)} className="text-blue-600">Edit</button>
            </div>
            <div className="flex mb-4">
              <p>Have a promo code?</p>
              <button className="text-blue-500" onClick = {openPromoModal}>
                <span className="mx-2">Apply here</span>
              </button>
            </div>
            {selectedOption && (
              <div className="flex justify-center mt-5">
                <button  
                  type="submit"
                  className="bg-[#191983] text-white py-2 px-4 rounded-lg"
                >
                  Pay ₹{getPrice()}
                </button>
              </div>
            )}
          </form>
        </div>
      )}
      <PromoCodeModal
        isOpen={promoModalOpen}
        onClose={() => setPromoModalOpen(false)}
        originalPrice={getPrice()}
        applyPromoCode={applyPromoCode}
      />
    </div>
    
  );
};

export default FinalPage;
