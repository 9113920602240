import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';

const TandC = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchHtmlFile = async () => {
      try {
        const response = await axios.get('https://terms-cup.pages.dev/');
        setHtmlContent(response.data);
      } catch (error) {
        console.error('Error fetching HTML file:', error);
      }
    };

    fetchHtmlFile();
  }, []);

  return (
    <>
      <Header />
      <div className="container mx-auto px-4 py-8" dangerouslySetInnerHTML={{ __html: htmlContent }} />
      <Footer />
    </>
  );
};

export default TandC;
