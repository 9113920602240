// src/Blog.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Tweet } from 'react-tweet'

const Blog = () => {
  return (
    <>
      <Header />
      <main className="tweet-container">
        <Tweet id="1803648852467294475" />
        <Tweet id="1791024020025929824" />
       
        <Tweet id="1802983920675557876" />

        <Tweet id="1801171677092425755" />
        <Tweet id="1790617608410276101" />
        
        <Tweet id="1768219661534134274" />
        {/* https://x.com/Kenu73/status/ */}
        <Tweet id="1792077798036942900" />
        <Tweet id="1803749262628643249" />
        <Tweet id="1790615703428706460" />
        {/* 1800835518264991803
        <Tweet id="1790615703428706460" /> */}
      </main>
      <Footer />
    </>
  );
};

export default Blog;