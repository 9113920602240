import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const HeroSection = () => {
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    navigate('/login');
  };

  return (
    <div className="flex flex-col h-screen items-center justify-center text-white text-center px-4 md:px-0" style={{background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))"}}>
      <h1 className="text-4xl md:text-6xl lg:text-7xl font-semibold leading-relaxed lg:mt-10">
        Prepare your Complicated Visa <br className="hidden md:block" /> Application with Ease
      </h1>
      <p className="text-xl md:text-2xl lg:text-3xl mt-4 mb-6">
        Expert Guidance and Personalized Feedback <br className="hidden md:block" /> hence reducing the rejection to{' '}
        <span 
          className="relative inline-block cursor-help"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          ZERO*
          {showTooltip && (
            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 w-64 p-4 mb-2 bg-white text-gray-800 text-sm rounded-lg shadow-lg transition-opacity duration-300 z-10">
              <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 w-3 h-3 bg-white rotate-45"></span>
              Though we will do our best and work hard for your success, Visas come with a lot of uncertainty. An actual guarantee is not possible, and the final decision lies with the visa issuing authority.
            </span>
          )}
        </span>
      </p>
      <button className="bg-white text-black font-semibold py-3 px-6 text-lg lg:text-2xl rounded-lg mt-6" onClick={handleClick}>
        Start Your Application
      </button>
      <Link to="/how-it-works" className="mt-6 text-white underline underline-offset-2 text-lg md:text-xl">
        Learn More
      </Link>
    </div>
  );
};

export default HeroSection;