import React, { useEffect, useState, useCallback } from 'react';
import Header from './Header';
import Footer from './Footer';
import person1 from '../assets/priyesh/p2.jpeg';
import person2 from '../assets/priyesh/p1.jpeg';
import person3 from '../assets/priyesh/p3.jpeg';
import person4 from '../assets/ridam/r2.jpg';
import person5 from '../assets/ridam/r1.jpg';
import person6 from '../assets/ridam/r3.webp';
import xIcon from '../assets/x.png';
import emailIcon from '../assets/email.png';

const Knowus = () => {
  const [scrollIndex, setScrollIndex] = useState(0);
  const phrases = ["Honesty", "Transparency", "Expertise"];

  const updateScrollIndex = useCallback(() => {
    setScrollIndex((prevIndex) => (prevIndex + 1) % phrases.length);
  }, [phrases.length]);

  useEffect(() => {
    const timer = setInterval(updateScrollIndex, 2000);
    return () => clearInterval(timer);
  }, [updateScrollIndex]);

  const teamMembers = [
    {
      name: "Priyesh Sharma",
      role: "Founder, Visa Specialist",
      description: ` 
      Engineer by profession and an avid traveler by passion who has been to 25+ countries has helped 100s 
      to receive their visa, also a professional with expertise in payments background, he reads and write's visa 
      like very few could and can make your travel unforgettable without losing an arm or a leg. 
      He has been doing it since more than 5 years and very active on X.`,
      images: [person1, person2, person3],
      social: {
        x: 'https://x.com/Kenu73',
        email: 'mailto:priyesh@viszapp.com',
      },
    },
    {
      name: "Ridam Jain",
      role: "Founder, CTO",
      description: `Software Engineer, finance geek, stutter, 
      previously worked at one of the top PoS providers in Ireland, 
      has been freelacing since 2018, took his first solo trek in Guj. in 2021, 
      inspired immensely by Naval, for him travelling is facing his fears, 
      to come closer to people and the mother nature and ofcourse the experience and perspectives.
       (ps. you can swipe right on our pics ;D)
      `,
      images: [person5, person4, person6],
      social: {
        x: 'https://x.com/rhythmnibjia',
        email: 'mailto:ridam@viszapp.com',
      },
    },
  ];

  const [imageIndex, setImageIndex] = useState(new Array(teamMembers.length).fill(0));

  const handleHover = (index) => {
    setImageIndex(prevIndexes => {
      const newIndexes = [...prevIndexes];
      newIndexes[index] = (newIndexes[index] + 1) % 3;
      return newIndexes;
    });
  };

  const handleSwipe = (index) => {
    setImageIndex(prevIndexes => {
      const newIndexes = [...prevIndexes];
      newIndexes[index] = (newIndexes[index] + 1) % 3;
      return newIndexes;
    });
  };

  return (
    <div>
      <Header />
      <div className="container mx-auto px-4 py-16">
        <div className="flex flex-col md:flex-row items-start justify-between">
          <div className="md:w-1/3 mb-8 md:mb-0 md:sticky md:top-20 self-start">
            <div className="text-center md:text-left">
              <h1 className="text-4xl md:text-5xl font-bold text-[#000080] mb-4">
                Meet the Team
              </h1>
              <p className="text-xl md:text-2xl text-gray-600 mb-2">
                that believes in
              </p>
              <div className="h-16 overflow-hidden">
                <div
                  className="transition-transform duration-500 ease-in-out"
                  style={{ transform: `translateY(-${scrollIndex * 4}rem)` }}
                >
                  {phrases.map((phrase, index) => (
                    <p key={index} className="text-2xl md:text-3xl text-[#000080] font-semibold h-16 flex items-center justify-center md:justify-start">
                      {phrase}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {teamMembers.map((member, index) => (
            <div key={index} className="md:w-1/4 mb-8 md:mb-0">
              <div 
                className="relative overflow-hidden group"
                onMouseEnter={() => handleHover(index)}
                onTouchStart={() => handleSwipe(index)}
              >
                <img src={member.images[imageIndex[index]]} alt={member.name} className="w-full h-80 object-cover rounded-lg shadow-lg transition-transform duration-300" />
              </div>
              <div className="mt-4">
                <h2 className="text-2xl font-bold text-[#000080]">{member.name}</h2>
                <p className="text-lg text-gray-600 mb-2">{member.role}</p>
                <p className="text-sm text-gray-700 mb-4">{member.description}</p>
                <div className="flex space-x-4">
                  <a 
                    href={member.social.x}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-[#000080] transition-colors duration-300"
                  >
                    <img src={xIcon} alt="Twitter Icon" className="w-6 h-6" />
                  </a>
                  <a 
                    href={member.social.email}
                    className="text-gray-600 hover:text-[#000080] transition-colors duration-300"
                  >
                    <img src={emailIcon} alt="Email Icon" className="w-6 h-6" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Knowus;
