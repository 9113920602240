import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(`${config.apiBaseUrl}/api/orders`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const convertToLocalTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // Adjust as needed for formatting
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'processing':
        return 'bg-yellow-500';
      case 'completed':
        return 'bg-green-600';
      case 'canceled':
        return 'bg-red-600';
      default:
        return 'bg-gray-500';
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-full text-lg">Loading order history...</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl h-full max-h-5xl p-6 lg:p-10 flex flex-col">
      <h1 className="text-2xl font-bold mb-6 text-black">Your Orders</h1>
      {orders.length === 0 ? (
        <p className="text-lg text-gray-600">You have no orders yet!</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-6 gap-y-8">
          {orders.map((order) => (
            <div
              key={order.bookid}
              className="relative bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition-all duration-300 ease-in-out"
            >
              {/* Slanted Status Label */}
              <div
                className={`absolute top-2 right-2 px-2 py-1 text-sm font-semibold text-white ${getStatusStyle(
                  order.status
                )}`}
                style={{
                  transform: 'rotate(45deg)', // Slant the label
                  transformOrigin: 'top right', // Position the rotation origin to the top right
                  top: '80px', // Adjust the vertical position to align the slant
                  right: '-15px', // Adjust the horizontal position for alignment
                  width: '120px', // Adjust width for the status label
                  textAlign: 'center',
                }}
              >
                {order.status}
              </div>

              {/* Order ID */}
              <p className="text-gray-500 text-sm">#{order.orderid}</p>

              {/* Visa Type and App Name */}
              <div className="text-center my-4">
                <p className="text-lg font-semibold text-indigo-600">{order.visatype}</p>
                <p className="text-lg font-semibold text-black mb-4">{order.app_name}</p>

                {/* View Order Button */}
                <button
                  className="bg-indigo-600 text-white py-1.5 px-2.5 rounded-md hover:bg-indigo-500 transition-all duration-300 ease-in-out"
                  onClick={() => console.log(`Viewing order ${order.bookid}`)}
                >
                  View
                </button>
              </div>

              {/* Plan and Date */}
              <div className="flex justify-between items-center mt-4">
                <p className="text-sm font-semibold text-gray-600">{order.plan}</p>
                <p className="text-sm font-semibold text-gray-600">{convertToLocalTime(order.createdon)}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
