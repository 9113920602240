import React from 'react';
import { Link } from 'react-router-dom';

const SideBar = ({ handleLogout }) => {
  return (
    <div className="lg:sticky lg:top-0 lg:h-screen flex-shrink-0 mt-10 lg:mt-16 p-6 lg:p-10">
      <nav className="flex flex-row lg:flex-col overflow-x-auto lg:space-y-16 space-x-6 lg:space-x-0">
        <Link to="/dashboard" className="block py-1 px-3 bg-white font-semibold lg:text-xl text-black rounded-2xl text-center">Dashboard</Link>
        <Link to="/visaSelection" className="block py-1 px-3 bg-white font-semibold lg:text-xl text-black rounded-2xl text-center">Book</Link>
        <Link to="/dashboard/orders" className="block py-1 px-3 bg-white font-semibold lg:text-xl text-black rounded-2xl text-center">Orders</Link>
        <a href="/" className="block py-1 px-3 bg-white font-semibold lg:text-xl text-black rounded-2xl text-center" onClick={handleLogout}>Logout</a>
      </nav>
    </div>
  );
};

export default SideBar;
