import React, { useState, useEffect } from 'react';
import background from "../assets/loginbackground.webp"
import {useNavigate, useLocation} from "react-router-dom"
import showPasswordIcon from '../assets/show-password.png';
import hidePasswordIcon from '../assets/hide.png';
import Swal from 'sweetalert2'
import Turnstile, { useTurnstile } from "react-turnstile";
import config from '../config';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

const Login = () => {
  const navigate = useNavigate();
  const turnstile = useTurnstile();
  const [email, setEmail] = useState('');
  const location = useLocation(); 
  const [turnstileToken, setTurnstileToken] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');


  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  
  // Check for the 'verified' query parameter and show the toast message
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    
    // Assuming the backend only appends 'verified=true' during a 302 redirect
    if (params.get('verified') === 'true') {
      Toast.fire({
        icon: "success",
        title: "Email verified, you can login now!"
      });
    }
    // eslint-disable-next-line
  }, [location.search]);
  

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
//  const handleClick=()=>{
//   navigate('/dashboard')
//  }
const handleGoogleSignUp = async (credentialResponse) => {
  
  const decoded = jwtDecode(credentialResponse.credential);
  const { email, sub, name } = decoded;

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  try {
  const loginResponse = await axios.post(`${config.apiBaseUrl}/api/users/google-signin`, { email, gid: sub, name, turnstileToken });

    if (loginResponse.status === 200 && loginResponse.data.token) {
      Toast.fire({
        icon: "success",
        title: loginResponse.data.message
      });
      localStorage.setItem('accessToken', loginResponse.data.token);
      navigate('/dashboard');
    } else {
      // Handle unexpected successful response without token
      Toast.fire({
        icon: "error",
        title: "Login successful but no token received"
      });
      turnstile.reset();
    }
  } catch (error) {

    // console.error('Error during Google Sign Up:', error);
    turnstile.reset();
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 401 || error.response.status === 403) {
        Toast.fire({
          icon: "error",
          title: error.response.data.message || "Authentication failed"
        });
      } else if (error.response.status === 500) {
        Toast.fire({
          icon: "error",
          title: "Server error. Please try again later."
        });
      } else if (error.response.status === 404) {
        Toast.fire({
          icon: "error",
          title: error.response.data.message || "Authentication failed"
        });
        navigate('/signup');
      } else {
        Toast.fire({
          icon: "error",
          title: error.response.data.message || "An error occurred"
        });
      }
    } else if (error.request) {
      // The request was made but no response was received
      Toast.fire({
        icon: "error",
        title: "No response from server. Please check your internet connection."
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      Toast.fire({
        icon: "error",
        title: "An unexpected error occurred"
      });
    }
  }
};
const handleLogin = async (event) => {
  event.preventDefault();
  setError('');

  // Form validation
  if (!email.trim() || !password.trim()) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });

    Toast.fire({
      icon: "error",
      title: "Please enter both email and password"
    });

    return; // Exit the function early if validation fails
  }

  try {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });

    const response = await fetch(`${config.apiBaseUrl}/api/users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, mode: 'signup', turnstileToken }),
    });

    const data = await response.json();
    console.log(data);

    if (!response.ok) {
      turnstile.reset();
      // Handle different error scenarios
      if (data.status === 401 || data.status === 403 || data.status === 500)  {
        Toast.fire({
          icon: "error",
          title: data.message || "Authentication failed"
        });
      } else {
        Toast.fire({
          icon: "error",
          title: data.message || "An error occurred"
        });
      }
      throw new Error(data.message || "Login failed");
    } 
    else if(response.ok){
      // If login is successful
      Toast.fire({
        icon: "success",
        title: "Login Successful"
      });

      const token = data.token;

      // Store the token in localStorage
      localStorage.setItem('accessToken', token);

      // Redirect to the dashboard
      navigate('/dashboard');
    }
  } catch (error) {
    turnstile.reset();
    console.error("Login error:", error);
    // This will only run if there's a network error or if we manually throw an error
    Toast.fire({
      icon: "error",
      title: error.message || "An unexpected error occurred"
    });
    setError(error.message || "An unexpected error occurred");
  }
};

return (
  <div className="flex flex-col items-center justify-center min-h-screen  relative" style={{background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))" }}>
    <div
      className="absolute inset-0 bg-cover bg-center opacity-50"
      style={{ backgroundImage: `url(${background})`, opacity: 0.08 }}
    ></div>
    <div className="relative text-center">
      <h2 className="text-2xl font-bold text-white mb-6">Viszapp</h2>
      <h3 className="text-3xl font-semibold text-white mb-11">Login</h3>
    </div>
    <div className="relative z-10 bg-white p-5 rounded-xl shadow-lg max-w-md w-full">
      <div className="m-7">
        <div className="flex justify-center mb-3">
          <GoogleLogin
            onSuccess={handleGoogleSignUp}
            onError={() => {
              console.log('Google Login Failed');
            }}
            uxMode="redirect"
            redirectUri={`${window.location.origin}/dashboard`}
          />
        </div>
        <p className="text-center text-gray-600 mb-1">Or</p>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-black mb-2" htmlFor="email">Email</label>
            <input
              className="w-full p-2 border border-gray-300 rounded-md"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </div>

          <div className="mb-4">
            <label className="block text-black mb-2" htmlFor="password">Password</label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                className="w-full p-3 border rounded-lg focus:outline-none focus:border-blue-500"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button className="text-blue-800 flex justify-end mx-2 mb-3">Forgot?</button>
              <button
                type="button"
                className="absolute right-3 top-3"
                onClick={toggleShowPassword}
              >
                {showPassword ? (
                  <img src={showPasswordIcon} alt="Show Password" className="h-7 w-7" />
                ) : (
                  <img src={hidePasswordIcon} alt="Hide Password" className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>

          <Turnstile
      sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
      theme='light'
      size='flexible'
      onVerify={(token) => {
        setTurnstileToken(token);
      }}
    />
          <button className="w-full bg-blue-700 text-white p-2 mt-3 rounded-md font-semibold" type="submit">Login now</button>

          <div className="text-center mt-4">
            <p className="text-black text-sm">
              By Signing in, you agree to the <a href="/terms" className="text-blue-900">T&C</a>, <a href="/cancellation" className="text-blue-900">Cancellation</a>, and <a href="/privacy" className="text-blue-900">Privacy Policy</a>.
            </p>
          </div>
        </form>
        {error && <p className="text-red-500 mt-4">{error}</p>}
        <div className="text-center mt-6">
          <p className="text-black">Don't Have An Account? <a href="/signup" className="text-blue-900">Sign Up</a></p>
        </div>
        <div className="text-center mt-6">
          <p className="text-black">Missing Home? <a href="/" className="text-blue-900">Home</a></p>
        </div>
      </div>
    </div>
  </div>
);
};

export default Login;
