import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from '../components/Home';
import Knowus from '../components/Knowus';
import Pricing from '../components/Pricing';
import HowItWorks from '../components/HowItWorks';
import Login from '../components/Login';
import SignUp from '../components/SignUp';
import VisaSelection from '../components/VisaSelection';
import AttachDocuments from '../components/AttachDocuments';
import AppointmentDate from '../components/AppointmentDate';
import ChoosePrice from '../components/ChoosePrice';
import Dashboard from '../components/Dashboard';
import Blogs from '../components/Blogs';
import Offers from '../components/Offers';
import OrderHistory from '../components/user/OrderHistory';
import Appointments from '../components/user/Appointments';
import ProtectedRoute from './ProtectedRoute';
import PrivacyPolicy from '../components/PrivacyPolicy';
import Cancellation from '../components/Cancellation';
import TandC from '../components/TandC';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/know-us" element={<Knowus />} />
      <Route path="/terms" element={<TandC />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/cancellation" element={<Cancellation />} />
      <Route path="/how-it-works" element={ <HowItWorks/>} />
      <Route path="/pricing" element={<Pricing/>} />
      <Route path="/login" element={
      <ProtectedRoute requireAuth={false}>
      <Login />
      </ProtectedRoute>
      } />
      <Route path="/signup" element={
        <ProtectedRoute requireAuth={false}>
        <SignUp />
      </ProtectedRoute>
      }/>
      <Route path="/visaSelection" element={
      <ProtectedRoute requireAuth={true}>
      <VisaSelection/>
      </ProtectedRoute>
      }/>
      <Route path="/attachDocuments" element={
      
      <ProtectedRoute requireAuth={true}>
      <AttachDocuments/>
            </ProtectedRoute>
      }/>
      <Route path="/appointment" element={
      <ProtectedRoute requireAuth={true}>
      <AppointmentDate/>
            </ProtectedRoute>
      }/>
      <Route path="/choosePrice" element={
      <ProtectedRoute requireAuth={true}>
<ChoosePrice/>
      </ProtectedRoute>
      }/>
      
     {/* Dashboard nested routes */}
     <Route path="/dashboard" element={<ProtectedRoute requireAuth={true}><Dashboard /></ProtectedRoute>}>
        <Route index element={<Appointments />} /> {/* Default content when /dashboard is visited */}
        <Route path="orders" element={<OrderHistory />} /> {/* /dashboard/order-history */}
      </Route>

      <Route path='/blogs' element={<Blogs/>}/>
      <Route path='/offers' element={<Offers/>}/>

      <Route path="*" element={<Navigate to="/login" />} />


    </Routes>
  );
};

export default AppRoutes;
